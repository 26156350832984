<template>
  <tp-modal name="modal-create-guarantee-voucher" width="50%" max-width="768px">
    <v-card class="border-0">
      <div class="card px-5 py-3 d-flex" height="56x">
        <div>
          <v-toolbar-title class="font-weight-bold">
            Tạo phiếu
            <span v-if="type === 'repair'"> dịch vụ</span>
            <span v-else> bảo hành</span>
          </v-toolbar-title>
        </div>

        <v-spacer></v-spacer>
        <!--        <div class="text-right">-->
        <!--          <p class="my-0">-->
        <!--            Người tạo: <b>{{ user.name }}</b>-->
        <!--          </p>-->
        <!--          <p class="my-0">-->
        <!--            Thời gian ghi: <b>{{ guaranteeVoucher.created_at | formatTime }}</b>-->
        <!--          </p>-->
        <!--        </div>-->
      </div>
    </v-card>

    <!--        Start: actions-->
    <div
      class="v-window-item tab-item grey lighten-3 pa-5 v-window-item--active"
    >
      <v-card>
        <!--        Start: body -->
        <div class="card modal-body-scroll">
          <div class="tab-item lighten-3 pa-5">
            <v-row class="mb-4 d-flex align-center">
              <v-col cols="6" class="py-0">
                <v-autocomplete
                  v-model="guaranteeVoucher.take_participant_id"
                  class="text-body-1 text-right"
                  hide-selected
                  clearable
                  dense
                  flat
                  hide-details
                  :items="employees"
                  item-text="name"
                  item-value="id"
                  :menu-props="{ maxWidth: '332px' }"
                  no-data-text="Không có dữ liệu"
                  placeholder="Kỹ thuật tiếp nhận"
                  outlined
                  single-line
                />
              </v-col>
              <v-col cols="6" class="py-0">
                <div class="d-flex">
                  <v-checkbox
                    label="Phần cứng"
                    class="mt-0 mb-0 pt-0"
                    hide-details
                    dense
                    v-model="guaranteeVoucher.require_hardware"
                  ></v-checkbox>
                </div>
              </v-col>
            </v-row>
            <v-row class="mb-4 d-flex align-center">
              <v-col cols="6" class="py-0">
                <v-autocomplete
                  v-model="guaranteeVoucher.branch_id"
                  class="tp-filter-autocomplete"
                  clearable
                  :items="branches"
                  dense
                  hide-details
                  hide-selected
                  item-text="name"
                  item-value="id"
                  no-data-text="Không có dữ liệu"
                  outlined
                  single-line
                  label="Outlined"
                  placeholder="Chọn chi nhánh"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-0">
                <span
                  v-if="
                    (serialInfo && serialInfo.serial_number) ||
                      (outerSerial && outerSerial.customer_id)
                  "
                ></span>
                <div v-else>
                  <div class="d-flex align-center">
                    <v-text-field
                      v-model="serialNumber"
                      class="text-body-1"
                      clearable
                      dense
                      hide-details="auto"
                      outlined
                      placeholder="Nhập số serial và ấn enter"
                      @keypress.enter="getSerialInfoBySerialNumber()"
                    ></v-text-field>
                  </div>
                  <div
                    class="mt-3 success--text text-right font-weight-bold"
                    @click="$modal.show({ name: 'modal-outer-serial' })"
                    style="cursor: pointer"
                  >
                    Thêm Serial chưa có trên hệ thống
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="mb-4" v-if="serialInfo && serialInfo.serial_number">
              <v-col cols="6" class="py-0">
                <!-- Start: Serial detail -->
                <v-card class="grey lighten-4 px-5 py-4" flat>
                  <div class="mb-1">
                    <span class="font-weight-bold">Serial: </span>
                    <span>{{ serialInfo.serial_number }}</span>
                  </div>
                  <div class="mb-1">
                    <span class="font-weight-bold">Tên sản phẩm: </span>
                    <span>{{ serialInfo.product_info.product_name }}</span>
                  </div>
                  <div class="mb-1">
                    <span class="font-weight-bold">SKU:</span>
                    {{ serialInfo.product_info.option_sku }} -
                    <span
                      class="text-subtitle"
                      v-html="serialInfo.product_info.option_name"
                    ></span>
                  </div>
                  <div class="mb-1">
                    <span class="font-weight-bold">Giá bán:</span>
                    <span>{{
                      serialInfo.product_info.sold_price | formatCurrency
                    }}</span>
                  </div>
                  <div class="mb-1">
                    <span class="font-weight-bold">Gói bảo hành: </span>
                    <span>{{ serialInfo.guarantee_info.name }}</span>
                  </div>
                  <div class="mb-1">
                    <span class="font-weight-bold">Ngày kích hoạt: </span>
                    <span>{{ serialInfo.guarantee_info.trigger_time }}</span>
                  </div>
                  <div class="mb-1">
                    <span class="font-weight-bold">Hạn bảo hành: </span>
                    <span
                      class="font-weight-bold"
                      :class="
                        compareDateWithToday(serialInfo.guarantee_info.due_time)
                          ? 'green--text'
                          : 'red--text'
                      "
                    >
                      {{ serialInfo.guarantee_info.due_time }}
                    </span>
                  </div>
                </v-card>
                <!-- End: Serial detail -->
              </v-col>
              <v-col cols="6" class="py-0">
                <!-- Start: Customer info -->
                <v-card class="grey lighten-4 px-5 py-4" flat>
                  <div class="mb-1">
                    <span class="font-weight-bold">Khách hàng: </span>
                    <span>{{ serialInfo.bought_by.name }}</span>
                  </div>
                  <div
                    class="mb-1"
                    v-if="serialInfo.bought_by && serialInfo.bought_by.phone"
                  >
                    <span class="font-weight-bold">SĐT: </span>
                    <span>{{
                      serialInfo.bought_by.phone | VMask("### ### ####")
                    }}</span>
                  </div>
                  <div>
                    <span class="font-weight-bold">Hóa đơn bán: </span>
                    <span>{{ serialInfo.bought_by.hdb_code }}</span>
                  </div>
                </v-card>
                <!-- End: Customer info -->
              </v-col>
            </v-row>
            <v-row class="mb-4" v-if="outerSerial && outerSerial.customer_id">
              <v-col cols="6" class="py-0">
                <!-- Start: Serial detail -->
                <v-card class="grey lighten-4 px-5 py-4" flat>
                  <div class="mb-1">
                    <span class="font-weight-bold">Serial: </span>
                    <span>{{ outerSerial.serial_number }}</span>
                  </div>
                  <div class="mb-1">
                    <span class="font-weight-bold">Tên sản phẩm: </span>
                    <span>{{ outerSerial.product_name }}</span>
                  </div>
                </v-card>
                <!-- End: Serial detail -->
              </v-col>
              <v-col cols="6" class="py-0">
                <!-- Start: Customer info -->
                <v-card class="grey lighten-4 px-5 py-4" flat>
                  <div class="mb-1">
                    <span class="font-weight-bold">Khách hàng: </span>
                    <span
                      v-if="outerSerial.customer && outerSerial.customer.name"
                      >{{ outerSerial.customer.name }}</span
                    >
                  </div>
                  <div
                    class="mb-1"
                    v-if="outerSerial.customer && outerSerial.customer.phone"
                  >
                    <span class="font-weight-bold">SĐT: </span>
                    <span>{{
                      outerSerial.customer.phone | VMask("### ### ####")
                    }}</span>
                  </div>
                </v-card>
                <!-- End: Customer info -->
              </v-col>
            </v-row>
            <div class="mt-4">
              <!--              <div class="font-weight-bold mb-2">Mô tả</div>-->
              <v-textarea
                class="text-body-1"
                dense
                hide-details="auto"
                placeholder="Mô tả tình trạng sản phẩm, những yếu tố đã kiểm tra"
                outlined
                v-model="guaranteeVoucher.take_notes"
              ></v-textarea>
            </div>
            <div>
              <div class="mt-4">
                <div class="d-flex flex-row align-center justify-space-between">
                  <div></div>
                  <v-btn
                    class="primary--text font-weight-bold text-none rounded px-0"
                    depressed
                    text
                    small
                    @click="$refs.fileGuarantee.click()"
                    style="min-width: auto;"
                  >
                    <v-icon class="mr-1" left>mdi-upload-outline</v-icon>
                  </v-btn>
                  <input
                    type="file"
                    hidden
                    ref="fileGuarantee"
                    accept=".png, .jpg"
                    @change="selectFile()"
                  />
                </div>
              </div>
              <v-divider class="mb-4 mt-0"></v-divider>
              <div
                class="d-flex align-center flex-wrap images"
                v-if="guaranteeVoucher.docs && guaranteeVoucher.docs.length > 0"
              >
                <div
                  v-for="(item, index) in guaranteeVoucher.docs"
                  :key="`image-${item}`"
                  class="image"
                  :style="`background-image: url('` + item + `')`"
                >
                  <v-btn
                    x-small
                    class="icon-remove-image"
                    icon
                    @click="guaranteeVoucher.docs.splice(index, 1)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
              <div
                v-if="
                  !guaranteeVoucher.docs ||
                    (guaranteeVoucher.docs &&
                      guaranteeVoucher.docs.length === 0)
                "
              >
                Không có ảnh đính kèm
              </div>
            </div>
            <v-alert class="mt-4 yellow lighten-4 text-subtitle-1" dense>
              Tài liệu có chữ ký của khách theo quy định của công ty. Tải form
              trả máy bảo hành tại "Đây"
            </v-alert>
          </div>
        </div>
      </v-card>
      <div class="d-flex align-center justify-center">
        <v-btn
          class="rounded-lg mt-5 px-7"
          color="primary"
          depressed
          :disabled="
            guaranteeVoucherStatusRequest.value ===
              'loading-createGuaranteeVoucher'
          "
          @click="
            guaranteeVoucherStatusRequest.value ===
            'loading-createGuaranteeVoucher'
              ? null
              : createGuaranteeVoucher()
          "
        >
          Xác nhận
        </v-btn>
      </div>
    </div>
  </tp-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    type: {
      type: String,
      default: "repair"
    }
  },
  data() {
    return {
      serialNumber: null,
      urlHotGirl: "https://pbs.twimg.com/media/EfyoodPUYAMuAje.jpg"
    };
  },
  filters: {
    formatTime(d) {
      const dateTime = new Date(d),
        hour = String(dateTime.getHours()).padStart(2, "0"),
        min = String(dateTime.getMinutes()).padStart(2, "0"),
        date = String(dateTime.getDate()).padStart(2, "0"),
        month = String(dateTime.getMonth() + 1).padStart(2, "0"),
        year = dateTime.getFullYear();

      return `${hour}:${min} - ${date}/${month}/${year}`;
    }
  },
  computed: {
    ...mapGetters({
      currentImage: "GUARANTEE_VOUCHER/currentImage",
      orderStatusRequest: "ORDER/statusRequest"
    }),
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    customer() {
      return this.$store.getters["CUSTOMER/customer"];
    },
    serialInfo() {
      return this.$store.getters["GUARANTEE_VOUCHER/serialInfo"];
    },
    outerSerial() {
      return this.$store.getters["GUARANTEE_VOUCHER/outerSerial"];
    },
    guaranteeVoucher() {
      return this.$store.getters["GUARANTEE_VOUCHER/guaranteeVoucher"];
    },
    guaranteeVoucherStatusRequest() {
      return this.$store.getters["GUARANTEE_VOUCHER/statusRequest"];
    },
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },
  methods: {
    async openModalCustomer(customerId) {
      await this.$store.dispatch("CUSTOMER/getCustomerById", customerId);

      this.$modal.show({ name: "modal-customer" });
    },

    validateCustomerMessages() {
      let messages = [];

      if (this.customer.gender === null) {
        messages.push("giới tính");
      }

      if (!this.customer.addresses.length) {
        messages.push("địa chỉ");
      }

      if (!this.customer.dob && !this.customer.yob) {
        messages.push("ngày sinh hoặc năm sinh");
      }

      return messages.length
        ? `Vui lòng cập nhật ${messages.join(", ")} cho khách hàng!`
        : "";
    },

    showModalUpdateCustomer() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: "Cập nhật thông tin khách hàng",
          message: this.validateCustomerMessages(),
          confirmBtn: {
            text: "Cập nhật",
            color: "primary",
            onClickHandler: () => {
              this.openModalCustomer(this.serialInfo.bought_by.id);
            }
          }
        }
      });
    },

    async createGuaranteeVoucher() {
      if (this.serialInfo) {
        await this.getCustomerById(this.serialInfo.bought_by.id);
      } else {
        await this.getCustomerById(this.outerSerial.customer.id);
      }

      if (this.validateCustomerMessages().length) {
        this.showModalUpdateCustomer();
        return;
      }

      // Set type
      if (this.type === "guarantee") {
        this.guaranteeVoucher.type = 1;
      } else {
        this.guaranteeVoucher.type = 2;
      }
      if (!this.user.branch_id) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Bạn chưa thuộc chi nhánh nào"
          }
        });
      } else if (!this.guaranteeVoucher.take_participant_id) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Bạn chưa chọn nhân viên kỹ thuật"
          }
        });
      } else if (!this.guaranteeVoucher.branch_id) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Bạn chưa chọn chi nhánh"
          }
        });
      } else {
        this.guaranteeVoucher.assigned_to_id = this.user.id;

        const objectSender = JSON.parse(JSON.stringify(this.guaranteeVoucher));
        let serials = [];

        await objectSender.options.forEach(option => {
          const arr = option.serials.map(item => {
            return {
              id: item.id,
              sold_price: option.price,
              unit_sold_price: item.option_price
            };
          });

          serials = serials.concat(arr);
        });

        objectSender.xuat_serial_ids = serials;
        objectSender.outer_serial_id = this.outerSerial
          ? this.outerSerial.id
          : null;
        // Request create
        await this.$store.dispatch(
          "GUARANTEE_VOUCHER/createGuaranteeVoucher",
          objectSender
        );
      }
      // Alert and replace router
      if (
        this.guaranteeVoucherStatusRequest.value ===
        "success-createGuaranteeVoucher"
      ) {
        if (
          this.guaranteeVoucher &&
          this.guaranteeVoucher.take_participant_id &&
          this.guaranteeVoucher.id
        ) {
          // add Invoice Participant
          await this.$store.dispatch("ORDER/addInvoiceParticipant", {
            model_id: this.guaranteeVoucher.id,
            model_name: "App\\Models\\BHSC",
            user_id: this.user.id,
            role: this.guaranteeVoucher.require_hardware
              ? "TECHNIQUE_HARDWARE"
              : "TECHNIQUE",
            note: this.guaranteeVoucher.take_notes,
            docs: this.guaranteeVoucher.docs ? this.guaranteeVoucher.docs : null
          });
          if (this.orderStatusRequest.value === "error-addInvoiceParticipant") {
            this.$toast.show({
              name: "toast-action-alert",
              payload: {
                message: "Lỗi tạo nhân viên kỹ thuật"
              }
            });
          }
        }
        // Replace router
        await this.$router.push({
          name: "guarantee_vouchers-detail",
          params: {
            guaranteeId: this.guaranteeVoucher.id
          },
          query: {
            type: this.type
          }
        });
      }

      if (
        this.guaranteeVoucherStatusRequest.value ===
        "error-createGuaranteeVoucher"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Có lỗi xảy ra. Vui lòng kiểm tra lại sau!"
          }
        });
      }
    },
    compareDateWithToday(d) {
      let converter = d;
      converter = converter.split("/");
      converter = `${converter[2]}-${converter[1]}-${converter[0]}`;

      const date = new Date(converter),
        today = new Date();

      date.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      return date >= today;
    },
    closeModal() {
      this.$modal.hide({
        name: "modal-create-guarantee-voucher"
      });
    },

    async getCustomerById(id) {
      await this.$store.dispatch("CUSTOMER/getCustomerById", id);
    },

    async getSerialInfoBySerialNumber() {
      if (!this.serialNumber || this.serialNumber === "") return;
      else {
        await this.$store.dispatch(
          "GUARANTEE_VOUCHER/getSerialInfoBySerialNumber",
          this.serialNumber
        );
        if (
          this.guaranteeVoucherStatusRequest &&
          this.guaranteeVoucherStatusRequest.value ===
            "error-getSerialInfoBySerialNumber"
        )
          this.$toast.show({
            name: "toast-action-alert",
            payload: {
              message:
                "Serial này còn hàng trong hệ thống, hoặc đang nằm trong phiếu bảo hành, sửa chữa khác."
            }
          });
        else if (
          this.guaranteeVoucherStatusRequest &&
          this.guaranteeVoucherStatusRequest.value ===
            "success-getSerialInfoBySerialNumber"
        ) {
          this.guaranteeVoucher.serial_id = this.serialInfo.id;

          this.getCustomerById(this.serialInfo.bought_by.id);
        }
      }
    },

    async selectFile() {
      // this.file = this.$refs.fileGuarantee.files;
      // this.$emit("change", URL.createObjectURL(this.file[0]));
      //
      // const formData = new FormData();
      //
      // Array.from(this.file).forEach(f => {
      //   formData.append("file", f);
      //   formData.append("folder", "hrm/labor-contracts");
      // });
      //
      // await this.uploadImage(formData);
      //
      // if (this.guaranteeVoucherStatusRequest.value === "success-uploadImage") {
      //   if (!this.guaranteeVoucher.docs) this.guaranteeVoucher.docs = [];
      //   this.guaranteeVoucher.docs.push(this.currentImage);
      // }
      // if (this.guaranteeVoucherStatusRequest.value === "error-uploadImage") {
      //   this.$toast.show({
      //     name: "toast-action-alert",
      //     payload: {
      //       message: this.guaranteeVoucherStatusRequest.message
      //         ? this.guaranteeVoucherStatusRequest.message
      //         : ""
      //     }
      //   });
      // }
      // // reset ref
      // const input = this.$refs.fileGuarantee;
      // input.type = "text";
      // input.type = "file";
    },
    async uploadImage(formData) {
      await this.$store.dispatch("GUARANTEE_VOUCHER/uploadImage", formData);
    }
  }
};
</script>

<style scoped lang="scss">
.border-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.v-card {
  box-shadow: none !important;
}
.images {
  .image {
    padding-top: 50px;
    width: 50px;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:hover .icon-remove-image {
      opacity: 1;
    }
    .icon-remove-image {
      opacity: 0;
      transition: all ease 0.2s;
      position: absolute;
      top: -5px;
      right: -5px;
      border-radius: 50%;
      cursor: pointer;
      z-index: 10;
      color: #868686;
      background: #ffffff4a;
      border: 1px solid #868686;
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}
</style>
