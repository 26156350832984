<template>
  <v-data-table
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    :headers="headers"
    hide-default-footer
    :items="guaranteeVouchers"
    item-key="id"
    :loading="
      guaranteeVoucherStatusRequest.value === 'loading-getGuaranteeVouchers'
    "
    loading-text="Đang tải dữ liệu"
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :options="dataTableOptions"
    @update:options="updateSort($event)"
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.code`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.code)"
          >
            {{ item.code }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.serial_number`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.serial_number)"
          >
            {{ item.serial_number }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.product_name`]="{ item }">
      <div class="my-1">
        <div class="">
          {{ item.product_name }}
        </div>
        <div
          class="sub--text text-subtitle-2 font-weight-light grey--text text--darken-2"
          v-html="item.option_name"
        ></div>
      </div>
    </template>

    <template v-slot:[`item.customer_phone`]="{ item }">
      <div style="width: 86px">
        <span>{{ item.customer_phone | VMask("### ### ####") }}</span>
      </div>
    </template>

    <template v-slot:[`item.service_supplier`]="{ item }">
      <div style="width: 86px">
        <span>{{
          item.service_supplier ? item.service_supplier.name : ""
        }}</span>
      </div>
    </template>

    <template v-slot:[`item.service_supplier`]="{ item }">
      <div style="width: 86px">
        <span>{{
          item.service_supplier ? item.service_supplier.name : ""
        }}</span>
      </div>
    </template>

    <template v-slot:[`item.total_fee`]="{ item }">
      <div style="width: 86px">
        <span v-if="item.code.slice(0, 2) === 'SC'">{{
          item.total_fee | formatCurrency
        }}</span>
        <span v-else>N/A</span>
      </div>
    </template>

    <template v-slot:[`item.customer_fee`]="{ item }">
      <div style="width: 86px">
        <span>{{ item.customer_fee | formatCurrency }}</span>
      </div>
    </template>

    <template v-slot:[`item.customer_value`]="{ item }">
      <div style="width: 86px">
        <span v-if="item.export && item.export.remains && item.customer_fee">{{
          (item.customer_fee - item.export.remains) | formatCurrency
        }}</span>
        <span v-else>{{ 0 | formatCurrency }}</span>
      </div>
    </template>

    <template v-slot:[`item.customer_remains`]="{ item }">
      <div style="width: 86px">
        <span v-if="item.export && item.export.remains">{{
          item.export.remains | formatCurrency
        }}</span>
        <span v-else>{{ 0 | formatCurrency }}</span>
      </div>
    </template>

    <template v-slot:[`item.step`]="{ item }">
      <div style="min-width: 150px" class="d-flex align-center">
        <v-chip
          v-if="item.export"
          class="font-weight-bold"
          color="success"
          small
        >
          Đã xuất phiếu
        </v-chip>
        <v-chip
          v-else
          class="font-weight-bold"
          :color="stepList[item.step - 1].color"
          small
          outlined
        >
          {{ stepList[item.step - 1].name }}
        </v-chip>
      </div>
    </template>

    <template v-slot:[`item.huy_status`]="{ item }">
      <v-chip
        class="font-weight-bold"
        :color="item.huy_status === 1 ? 'red accent-2' : 'grey'"
        small
        outlined
      >
        {{ item.huy_status === 1 ? "Đã hủy" : "Không" }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
import { HEADERS_LISTING_GUARANTEE_VOUCHER } from "@/modules/Guarantee/constants";

export default {
  props: {
    sort: {
      type: Object
    }
  },

  data() {
    return {
      headers: HEADERS_LISTING_GUARANTEE_VOUCHER,
      stepList: [
        { id: 1, name: "Đã tiếp nhận", color: "amber" },
        { id: 2, name: "Đang xử lý", color: "deep-purple" },
        { id: 3, name: "Xử lý xong", color: "blue" },
        { id: 4, name: "Đã trả khách", color: "green" }
      ]
    };
  },

  computed: {
    dataTableOptions() {
      if (this.sort && this.sort.field) {
        return {
          sortBy: [this.sort.field],
          sortDesc: [this.sort.type === 2]
        };
      } else return null;
    },

    guaranteeVouchers() {
      return this.$store.getters["GUARANTEE_VOUCHER/guaranteeVouchers"];
    },

    guaranteeVoucherStatusRequest() {
      return this.$store.getters["GUARANTEE_VOUCHER/statusRequest"];
    }
  },

  methods: {
    cancelGuaranteeVoucher(item) {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Hủy phiếu chuyển hàng <strong>${item.code}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Tiếp tục",
            onClickHandler: async () => {
              await this.$store.dispatch(
                "GUARANTEE_VOUCHER/cancelGuaranteeVoucher",
                {
                  id: item.id,
                  index: this.getIndexOfGoodDeliveries(item.id)
                }
              );
              if (
                this.guaranteeVoucherStatusRequest.value ===
                "success-cancelGuaranteeVoucher"
              ) {
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã hủy phiếu chuyển hàng"
                  }
                });
              }
            }
          }
        }
      });
    },

    getIndexOfGoodDeliveries(val) {
      const arr = this.guaranteeVouchers.map(item => item.id);

      return arr.indexOf(val);
    },

    updateSort(val) {
      if (val.sortBy) {
        this.$emit("updateSort", {
          type: val.sortDesc.length > 0 && val.sortDesc[0] ? 2 : 1,
          field: val.sortBy[0]
        });
      }
    },

    async viewDetail(item) {
      // Reset payment list in state
      await this.$store.dispatch("SALE/setPaymentList", []);
      await this.$router.push({
        name: "guarantee_vouchers-detail",
        params: {
          guaranteeId: item.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
  .sub--text {
    ::v-deep p {
      margin-bottom: 0;
    }
  }
}
</style>
