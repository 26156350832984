<template>
  <div class="action mr-5 mb-4">
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between">
      <div class="action-row--col flex-grow-1 pr-5">
        <tp-search-option
          :default-option="searchType"
          :options="searchOptions"
          :value="searchKey"
          @search="updateSearchKey($event)"
        ></tp-search-option>
      </div>
      <div class="action-row--col">
        <v-btn
          class="rounded-lg mr-3"
          color="primary"
          depressed
          @click="showModalCreateGuarantee('guarantee')"
        >
          <v-icon left>mdi-plus</v-icon> Bảo hành
        </v-btn>

        <!--        gotoDetailPage('repair') -->
        <v-btn
          class="rounded-lg mr-3"
          color="primary"
          dark
          depressed
          @click="showModalCreateGuarantee('repair')"
        >
          <v-icon left>mdi-plus</v-icon> Sửa chữa
        </v-btn>

        <v-menu
          max-height="200px"
          nudge-top="0px"
          offset-y
          rounded="lg"
          transition="scroll-y-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="rounded-lg"
              color="primary"
              dark
              depressed
              v-bind="attrs"
              v-on="on"
              :loading="
                [
                  'loading-exportReportGuarantee',
                  'loading-exportReportRepair'
                ].includes(guaranteeVoucherStatusRequest.value)
              "
              outlined
            >
              <v-icon left>mdi-file-download-outline</v-icon>

              Xuất file
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item link @click="$emit('exportReportGuarantee')">
              Bảo hành
            </v-list-item>
            <v-list-item link @click="$emit('exportReportRepair')">
              Sửa chữa
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <!-- End: Action row -->
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between mt-4">
      <div class="action-row--col align-center">
        <!-- Start: Show filter dropdown -->
        <tp-btn-overflow
          :items="showFilterList"
          :selected-value="selectedShowFilterId"
          btn-class="white rounded-lg"
          @change="selectedShowFilterIndex = $event"
          @select="selectShowFilter($event)"
        ></tp-btn-overflow>
        <!-- End: Show filter dropdown -->
      </div>
      <div class="action-row--col d-flex align-center">
        <div class="text-body-2">
          {{ paginationInfo.from }} - {{ paginationInfo.to }} trong số
          {{ paginationInfo.itemTotal }}
        </div>
        <v-pagination
          v-model="curPage"
          :length="paginationInfo.pageTotal"
          :total-visible="5"
          color="primary"
        ></v-pagination>
      </div>
    </div>
    <!-- End: Action row -->
    <modal-create-guarantee-voucher :type="typeModalCreate" />
    <modal-add-outer-serial
      :guaranteeVoucherStatusRequest="guaranteeVoucherStatusRequest"
    />
  </div>
</template>

<script>
import ModalAddOuterSerial from "@/modules/Guarantee/pages/GuaranteeVouchers/Detail/components/modal/ModalAddOuterSerial";
import ModalCreateGuaranteeVoucher from "@/modules/Guarantee/pages/GuaranteeVouchers/Listing/modal/ModalCreateGuaranteeVoucher";
export default {
  props: {
    currentPage: {
      type: Number
    },
    selectedShowFilterId: {
      type: Number
    },
    searchKey: {
      type: String
    },
    searchType: {
      type: Number
    },
    searchOptions: {
      type: Array
    }
  },
  components: {
    ModalCreateGuaranteeVoucher,
    ModalAddOuterSerial
  },
  data() {
    return {
      typeModalCreate: "repair",
      actionList: [{ id: "delete", text: "Xóa" }],
      showFilterList: [
        { id: 25, text: "Hiển thị 25" },
        { id: 50, text: "Hiển thị 50" },
        { id: 100, text: "Hiển thị 100" }
      ]
    };
  },
  computed: {
    curPage: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("updateCurrentPage", val);
      }
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },
    paginationInfo() {
      return this.$store.getters["GUARANTEE_VOUCHER/paginationInfo"];
    },
    guaranteeVoucherStatusRequest() {
      return this.$store.getters["GUARANTEE_VOUCHER/statusRequest"];
    }
  },
  methods: {
    async showModalCreateGuarantee(type) {
      await this.$store.dispatch("GUARANTEE_VOUCHER/setGuaranteeVoucher", {
        serial_id: null,
        branch_id:
          this.user && this.user.branch_id ? this.user.branch_id : null,
        assigned_to_id: null,
        tinh_trang_may: null,
        ly_do_huy: null,
        attached_file: null,
        take_participant_id: this.user && this.user.id ? this.user.id : null,
        step: 1,
        type: null,
        options: [],
        xuat_serial_ids: [],
        sc_fee: null,
        reception_technique: null,
        payer: null,
        require_hardware: false,
        created_at: Date.now(),
        docs: []
      });
      await this.$store.dispatch("GUARANTEE_VOUCHER/resetOuterSerial");
      await this.$store.dispatch("GUARANTEE_VOUCHER/setSerialInfo", null);
      await this.$store.dispatch("SALE/setPaymentList", []);
      this.typeModalCreate = type;
      this.$modal.show({
        name: "modal-create-guarantee-voucher"
      });
    },
    async gotoDetailPage(type) {
      await this.$store.dispatch("SALE/setPaymentList", []);
      await this.$store.dispatch("SALE_RECEIPT/resetSaleReceipt");
      await this.$store.dispatch("GUARANTEE_VOUCHER/resetOuterSerial");
      await this.$store.dispatch("GUARANTEE_VOUCHER/resetGuaranteeVoucher");
      await this.$store.dispatch("GUARANTEE_VOUCHER/setSerialInfo", null);
      await this.$store.dispatch(
        "GUARANTEE_VOUCHER/setExportIdForGuaranteeVoucher",
        null
      );
      await this.$router.push({
        name: "guarantee_vouchers-create",
        query: {
          type: type
        }
      });
    },

    selectShowFilter(val) {
      this.$emit("updateItemPerPage", val.id);
    },

    updateSearchKey(val) {
      this.$emit("updateSearchKey", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.action {
  &-row {
    &--col {
      display: flex;
    }
  }
}
</style>
