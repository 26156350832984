<template>
  <div class="d_flex">
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :branches-filter="branchesFilter"
        :cancel-status-filter="cancelStatusFilter"
        :date-filter="dateFilter"
        :employees-filter="employeesFilter"
        :status-filter="statusFilter"
        :type-filter="typeFilter"
        @updateBranchesFilter="updateBranchesFilter($event)"
        @updateCancelStatusFilter="updateCancelStatusFilter($event)"
        @updateDateFilter="updateDateFilter($event)"
        @updateEmployeesFilter="updateEmployeesFilter($event)"
        @updateStatusFilter="updateStatusFilter($event)"
        @updateTypeFilter="updateTypeFilter($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :search-key="searchKey"
        :search-options="searchOptions"
        :search-type="searchType"
        :selected-show-filter-id="itemPerPage"
        @exportReportGuarantee="exportReportGuarantee"
        @exportReportRepair="exportReportRepair"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items
          :sort="sort"
          @updateSort="updateSort($event)"
        ></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import TopAction from "./components/TopAction";
import { exportExcelFile } from "@/core/composables";
import { CANCEL_STATUSES } from "@/modules/Guarantee/constants";

export default {
  components: {
    DataTableItems,
    FilterArea,
    TopAction
  },

  data() {
    return {
      branchesFilter: null,
      cancelStatusFilter: 0,
      currentPage: 1,
      dateFilter: {
        value: null,
        type: 1
      },
      employeesFilter: [],
      itemPerPage: 50,
      searchKey: null,
      searchOptions: [
        { type: "customer_name", label: "Theo tên khách hàng" },
        { type: "customer_phone", label: "Theo SĐT khách hàng" },
        { type: "serial_number", label: "Theo Serial" },
        { type: "product_name", label: "Theo tên sản phẩm" },
        { type: "code", label: "Theo mã phiếu" },
        { type: "order_code", label: "Theo mã đơn hàng" },
        {
          type: "serial_number_add",
          label: "Theo linh kiện phục vụ bảo hành sửa chữa"
        }
      ],
      searchType: 0,
      sort: {
        type: null,
        field: null
      },
      statusFilter: [],
      typeFilter: null
    };
  },

  watch: {
    "$route.query"(val) {
      this.getGuaranteeVouchers(val);
    }
  },

  async created() {
    const route = this.$route;

    await this.getGuaranteeVouchers(route.query);

    await this.$store.dispatch("GUARANTEE_VOUCHER/resetOuterSerial");
    await this.$store.dispatch("GUARANTEE_VOUCHER/setSerialInfo", null);
  },

  methods: {
    exportReportGuarantee() {
      exportExcelFile(this, {
        storeName: "GUARANTEE_VOUCHER",
        actionStoreName: "exportReportGuarantee",
        payload: {
          branchIds: this.branchesFilter,
          employeeIds: this.employeesFilter,
          step: this.statusFilter,
          cancelStatus: this.cancelStatusFilter,
          fromDate: this.dateFilter.value ? this.dateFilter.value[0] : null,
          toDate: this.dateFilter.value ? this.dateFilter.value[1] : null
        },
        fileName: "bao-cao-bao-hanh",
        isCheckExportTime: true
      });
    },
    exportReportRepair() {
      exportExcelFile(this, {
        storeName: "GUARANTEE_VOUCHER",
        actionStoreName: "exportReportRepair",
        payload: {
          branchIds: this.branchesFilter,
          employeeIds: this.employeesFilter,
          step: this.statusFilter,
          cancelStatus: this.cancelStatusFilter,
          fromDate: this.dateFilter.value ? this.dateFilter.value[0] : null,
          toDate: this.dateFilter.value ? this.dateFilter.value[1] : null
        },
        fileName: "bao-cao-sua-chua",
        isCheckExportTime: true
      });
    },

    getIndexOfSearchOption(option) {
      const types = this.searchOptions.map(item => item.type);

      return types.indexOf(option);
    },

    async getGuaranteeVouchers(query) {
      if (query.page) {
        // Set filtered branches
        this.branchesFilter =
          typeof query.branch_id === "string"
            ? [parseInt(query.branch_id)]
            : typeof query.branch_id === "object"
            ? query.branch_id.map(item => parseInt(item))
            : [];
        // Set filtered cancel status
        this.cancelStatusFilter = query.cancel_status
          ? parseInt(query.cancel_status)
          : 0;
        // Set current page
        this.currentPage = parseInt(query.page);
        // Set filtered date
        this.dateFilter = query.date_from
          ? {
              value: [query.date_from, query.date_to],
              type: parseInt(query.date_type)
            }
          : { value: null, type: 1 };
        // Set filtered employees
        this.employeesFilter =
          typeof query.created_by_id === "string"
            ? [parseInt(query.created_by_id)]
            : typeof query.created_by_id === "object"
            ? query.created_by_id.map(item => parseInt(item))
            : [];
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set search key
        this.searchKey = query.search || null;
        // Set search type
        this.searchType = query.search ? parseInt(query.search_type) : 0;
        // Set sort field
        this.sort.field = query.sort_by || null;
        this.sort.type = query.sort_type ? parseInt(query.sort_type) : null;
        // Set filtered status
        this.statusFilter =
          typeof query.status === "string"
            ? [parseInt(query.status)]
            : typeof query.status === "object"
            ? query.status.map(item => parseInt(item))
            : [];
        // Set filtered type
        this.typeFilter = query.type ? parseInt(query.type) : "all";

        // Get guarantee vouchers
        await this.$store.dispatch("GUARANTEE_VOUCHER/getGuaranteeVouchers", {
          filter: {
            branch_id: this.branchesFilter,
            created_by_id: this.employeesFilter,
            step: this.statusFilter,
            cancel_status:
              typeof this.cancelStatusFilter === "number" &&
              this.cancelStatusFilter !== CANCEL_STATUSES.ALL
                ? [this.cancelStatusFilter]
                : null,
            type:
              this.typeFilter && this.typeFilter !== "all"
                ? [this.typeFilter]
                : null,
            time_from:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[0]
                : null,
            time_to:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[1]
                : null
          },
          search: this.searchKey,
          search_key: this.searchOptions[this.searchType].type,
          sort: {
            type: this.sort.type,
            field: this.sort.field
          },
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    async pushRouter() {
      await this.$router.push({
        name: "guarantee_vouchers",
        query: {
          type:
            this.typeFilter && this.typeFilter !== "all"
              ? this.typeFilter
              : undefined,
          status:
            this.statusFilter && this.statusFilter !== "all"
              ? this.statusFilter
              : undefined,
          cancel_status: this.cancelStatusFilter,
          date_type:
            this.dateFilter.value &&
            this.dateFilter.value[0] &&
            this.dateFilter.type
              ? this.dateFilter.type
              : undefined,
          date_from:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[0]
              : undefined,
          date_to:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[1]
              : undefined,
          branch_id:
            this.branchesFilter && this.branchesFilter.length > 0
              ? this.branchesFilter
              : undefined,
          created_by_id:
            this.employeesFilter && this.employeesFilter.length > 0
              ? this.employeesFilter
              : undefined,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          search_type:
            this.searchKey && this.searchKey.length > 0
              ? this.searchType
              : undefined,
          sort_by: this.sort && this.sort.field ? this.sort.field : undefined,
          sort_type:
            this.sort && this.sort.field && this.sort.type
              ? this.sort.type
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    updateBranchesFilter(val) {
      this.branchesFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateCancelStatusFilter(val) {
      this.cancelStatusFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateDateFilter(val) {
      this.dateFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateEmployeesFilter(val) {
      this.employeesFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;

      if (val.option) {
        this.searchType = this.getIndexOfSearchOption(val.option);
      }

      this.currentPage = 1;

      // Push router query
      this.pushRouter();
    },

    updateSort(val) {
      this.sort = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateStatusFilter(val) {
      this.statusFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateTypeFilter(val) {
      this.typeFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
